import * as React from 'react';
import {Helmet} from 'react-helmet';

const Page = ({title, mainClass, children}: React.PropsWithChildren<{title?: string; mainClass?: string}>) => {
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content={
            'Cribl Cloud is the leading data observability platform in the world. Try Stream in the cloud today!'
          }
        />
        <title>{`Cribl.Cloud${title ? ` - ${title}` : ''}`}</title>
        <link rel='canonical' href='https://cribl.cloud' />
      </Helmet>
      <main key={title} className={mainClass ? mainClass : 'main-holder'}>
        {children}
      </main>
    </>
  );
};

export default Page;
