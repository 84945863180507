import * as React from 'react';
import '../styles/App.less';
import {Forgot} from '../components/Forgot/Forgot';
import Page from '../components/Layout/Page';

// markup
const IndexPage = () => {
  return (
    <Page title='Forgot'>
      <Forgot />
    </Page>
  );
};

export default IndexPage;
