import {Button, Card, Col, Form, Input} from 'antd';
import * as React from 'react';
import {useState} from 'react';
// @ts-ignore
import CriblIcon from '../../static/svgs/criblCloud.white.svg';
import {Link, navigate} from 'gatsby';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {MainLayout} from '../Layout/MainLayout';
import {forgot} from '../../services/API';

export const Forgot = () => {
  const [emailAddress, setEmailAddress] = useState('');

  const layout = {};

  return (
    <MainLayout>
      <Card className={'main-card'}>
        <div style={{textAlign: 'center', marginTop: '5em', marginBottom: '1em'}}>
          <CriblIcon />
        </div>
        <Form {...layout} className={'main-form-section'}>
          <Col offset={4} span={16} style={{textAlign: 'center'}}>
            <Form.Item name='email' rules={[{required: true, message: 'Please input your email!'}]}>
              <Input onChange={(e) => setEmailAddress(e.target.value)} placeholder={'Enter Email'} />
            </Form.Item>
          </Col>
          <Col offset={4} span={16} style={{textAlign: 'center'}}>
            <Button
              style={{width: '100%'}}
              onClick={() => forgot(emailAddress, () => navigate(`/reset/?email=${encodeURIComponent(emailAddress)}`))}
              type='primary'
              htmlType='submit'
              className={'orange-button'}
            >
              Reset Password
            </Button>
          </Col>
          <Col span={16} offset={4} style={{color: '#fff', marginTop: '10em'}}>
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                top: '8px',
                float: 'left',
                marginBottom: '5px'
              }}
            >
              <Link to={'/'} style={{color: '#fff'}}>
                <ArrowLeftOutlined /> Back to Log in
              </Link>
            </div>
          </Col>
        </Form>
      </Card>
    </MainLayout>
  );
};
